/* GLOBALS----------------------------------------------------------- */
/* fonts */
@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  src: local("Gilroy-SemiBold"),
    url("./media/fonts/Gilroy-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  src: local("Gilroy-Light"),
    url("./media/fonts/Gilroy-Light.woff") format("woff");
}

h1,
.title {
  font-family: "Gilroy-SemiBold";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  color: #fff;
  margin: 0;
}
.sub-title {
  font-size: 1.25rem;
}
h2,
p,
body,
.txt {
  font-family: "Gilroy";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
}
.sec-txt {
  font-family: "Gilroy-SemiBold";
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.4375rem;
  color: #fff;
}
.nav-txt {
  font-family: "Gilroy";
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  color: #fff;
}
.nav-txt-hlght,
.txt-hlght {
  font-family: "Gilroy-SemiBold";
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #fff;
}
.txt-hlght {
  font-size: 0.875rem;
}
.sub-txt {
  font-family: "Gilroy";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.5);
}
.text-trans-cap {
  text-transform: capitalize;
}

.light-txt {
  color: rgba(255, 255, 255, 0.3);
}

/* spacing */
.spacing {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0 16px;
  position: relative;
  /* border: 1px solid blue; */
}

/* comps */
body {
  background: #212121;
  margin: 0;
  transition: transform 0.25s cubic-bezier(0.25, 1, 0.5, 1);
  /* overflow-x: hidden; */
}
nav > * {
  box-sizing: border-box;
  padding: 0 16px;
  height: 36px;
  display: flex;
  align-items: center;
  font-style: normal;
  color: #fff;
}
a {
  text-decoration: none;
}

.link-button {
  width: 160px;
}
.link-button svg {
  height: 24px;
  opacity: 0.5;
  margin-left: 8px;
}
.link-button:hover svg {
  opacity: 1;
}
.link-button svg path {
  fill: #fff;
}

.img-shadow {
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.12),
    0px 5px 10px rgba(0, 0, 0, 0.09);
}

/* buttons */
button,
.button {
  font-family: "Gilroy";
  font-size: inherit;
  background: none;
  border: none;
  padding: 0 16px;
  height: 36px;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
}
button:hover,
.button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.but-line {
  border: 1px solid #fff;
}
.but-fill {
  background: rgba(255, 255, 255, 0.1);
}
.but-fill:hover {
  background: rgba(255, 255, 255, 0.3);
}
.svg-button-sml {
  /* paddings: 0 5px; */
}
.svg-button-sml svg {
  width: 24px;
  height: 24px;
  display: block;
}

.svg-button {
  padding: 4px;
  border-radius: 2px;
  margin-right: 8px;
}
.svg-button svg {
  width: 32px;
  height: 32px;
  display: block;
}
.svg-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* animations */

/* classes */
.flex-cent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-out {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-rit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-lft {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-col-out {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-col-rit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.moile-only {
  display: flex;
}
.desktop-only {
  display: none;
}

.sec-wrap {
  width: 100%;
  padding-top: 32px;
  position: relative;
  overflow: hidden;
}
.sec-wrap > * {
  margin-bottom: 120px;
}

.grid-wrapper {
  position: absolute;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

/* LOCALS-------------------------------------------------------------------- */
.content-cont {
  width: 100%;

  min-height: 100vh;
  overflow-x: hidden;
}
.content-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: 100% 220px;
  transition: 0.25s cubic-bezier(0.25, 1, 0.5, 1);
}
.cont-act-sidNav {
  transform: translateX(-220px);
}
.body-act-sidNav {
  opacity: 0.5;
}

@media only screen and (min-width: 768px) {
  .spacing {
    margin: auto;
    width: 720px;
  }
  .moile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
  .content-wrap {
    grid-template-columns: 1fr;
  }
  .content-wrap > *:last-child {
    display: none;
  }
  .sec-wrap {
    padding-top: 48px;
  }
  .sec-wrap > * {
    /* margin-bottom: 120px; */
  }
  .nav-txt,
  .nav-txt-hlght,
  .txt-hlght {
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 960px) {
  .spacing {
    margin: auto;
    width: 940px;
  }
  .sec-wrap {
    padding-top: 64px;
  }
  .sec-wrap > * {
    margin-bottom: 160px;
  }
}

@media only screen and (min-width: 1200px) {
  h2,
  p,
  body,
  .txt,
  .nav-txt,
  .nav-txt-hlght,
  .txt-hlght {
    font-size: 1rem;
    line-height: 28px;
  }
  .sub-txt {
    font-size: 0.875rem;
    line-height: 20px;
  }

  .spacing {
    margin: auto;
    width: 1140px;
  }
  .sec-wrap {
    padding-top: 80px;
    /* padding-top: 40px; */
  }
  .sec-wrap > * {
    margin-bottom: 200px;
  }
}
